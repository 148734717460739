import captCashprize from '@assets/captures/captCashprize.png';
import captEditMatchs from '@assets/captures/captEditMatchs.png';
import captGetFunds from '@assets/captures/captGetFunds.png';
import captHandleActivities from '@assets/captures/captHandleActivities.png';
import captHandleStructures from '@assets/captures/captHandleStructures.png';
import captHandlingEvents from '@assets/captures/captHandlingEvents.png';
import captPersonnalizeProfil from '@assets/captures/captPersonnalizeProfil.png';
import captProfilEsport from '@assets/captures/captProfilEsport.png';
import BandeBleu from '@assets/Home/bandes/bande_bleu.png';
import BandeViolette from '@assets/Home/bandes/bande_violette.png';
import BandeViolette2 from '@assets/Home/bandes/bande_violette_2.png';
import {
  Domain,
  Edit,
  EmojiEvents,
  Gamepad,
  GroupAdd,
  ManageAccounts,
  PriceCheck,
} from '@mui/icons-material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Hidden, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import {
  selectAllBackgroundImages,
  setBackgroundImages,
} from 'domain/backgroundImage/store/BackgroundImageSlice';
import { GAME_ACTIVE_LIST, GAME_IMAGES } from 'domain/game/GameActiveList';
import { selectAllNeons, setNeons } from 'domain/neon/store/NeonSlice';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomainToContext } from 'primary/hooks/useRetrieveFromDomain';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { RootState } from 'store';

import { Section } from '../../Components/Section/Section';
import { useTranslate } from '../../hooks/useTranslate';
import { HomeSectionProps } from '../Home';

const responsiveConf = [
  {
    breakpoint: 1024,
    settings: {
      slidesPerRow: 1,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesPerRow: 1,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesPerRow: 1,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
];

export const HomeSlidersSection = ({ className }: HomeSectionProps) => {
  const navigate = useNavigate();
  const { backgroundImageRepostory, neonRepository, allProfilRepository } =
    useContextDependency();
  const theme = useTheme();
  const smallerThanMid = useMediaQuery(theme.breakpoints.down('md'));
  const [, , neons] = useRetrieveFromDomainToContext(
    () => neonRepository.getAllNeons(),
    undefined,
    true,
    setNeons,
    selectAllNeons,
  );
  const t = useTranslate();

  const [, , backgrounds] = useRetrieveFromDomainToContext(
    () => backgroundImageRepostory.getAllBackgroundImages(),
    undefined,
    true,
    setBackgroundImages,
    (state: RootState) => selectAllBackgroundImages(state),
  );
  return (
    <Section className={className}>
      <div className={'-home-slider-event-with-mercato'}>
        <div className={'-diagonal-effect'}></div>
        <div className={'-content'}>
          <h1 className={'-title'}>{t('home.mercatoWithEvent.title')}</h1>
          <div className={'-row -wrapped -stretch'}>
            <div className={'-mercato'}>
              <div className={'-linkMercatoHome'}>
                <Link to={routesConfig.mercato.to()}>
                  <div className={'-top--title'}>
                    <img
                      alt={'bande-violette'}
                      src={BandeViolette}
                      className={'-img-bande -violette'}
                    />
                    <img
                      alt={'bande-violette-variant'}
                      src={BandeViolette2}
                      className={'-img-bande -violette2'}
                    />
                  </div>
                  <div className={'-title'}>
                    <h2>{t('home.mercato.title')}</h2>
                    <span>{t('home.mercato.subtitle')}</span>
                  </div>
                  <div className={'-bottom--title'}>
                    <img
                      alt={'bande-bleu'}
                      src={BandeBleu}
                      className={'-img-bande -bleu'}
                    />
                  </div>
                </Link>
              </div>
              <div className={'-row -timeline'}>
                <Timeline
                  className={'timelineHome'}
                  position={smallerThanMid ? 'left' : 'alternate'}
                >
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden lgDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captPersonnalizeProfil}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>
                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <Edit className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <h2>Personnalise ton profil</h2>
                      <p>
                        Profite de <strong className={'-accent'}>néons</strong> et{' '}
                        <strong className={'-accent'}>fonds</strong> incroyables,
                        renseigne les jeux auxquel tu joue, les détails de ton profil. Et
                        mets une
                        <strong className={'-accent'}> vidéo promotionnelle</strong>
                      </p>
                      <Hidden lgUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captPersonnalizeProfil}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden lgDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captProfilEsport}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <GroupAdd className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <h2>Profite d'une page dédiée a ton profil e-sport</h2>
                      <p>
                        Rend ton{' '}
                        <strong className={'-accent'}>
                          profil e-sport accessible à tous
                        </strong>{' '}
                        depuis n'importe ou.
                        <strong className={'-accent'}>Partage le sur les réseaux</strong>,
                        augmente ta présence dans le monde de l'e-sport
                      </p>
                      <Hidden lgUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captProfilEsport}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden lgDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandleActivities}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <Gamepad className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <h2>Gére toutes tes activités e-sports depuis Unpy</h2>
                      <p>
                        Utilise le Dashboard "Mes activité" pour géré tes demandes en
                        attentes, tes{' '}
                        <strong className={'-accent'}>prochains matchs</strong> et tes{' '}
                        <strong className={'-accent'}>derniers résultats</strong>
                      </p>
                      <Hidden lgUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandleActivities}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden lgDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandleStructures}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <Domain className={'-icon'} />
                      </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                      <h2>Créé et gére ta propre structure</h2>
                      <p>
                        Rejoint ou créé une{' '}
                        <strong className={'-accent'}>
                          structure e-sport amateur ou professionnel{' '}
                        </strong>{' '}
                        pour agrandir ton réseaux,
                        <strong className={'-accent'}>
                          {' '}
                          organiser tes compétitions
                        </strong>{' '}
                        ou assurer le future de ta{' '}
                        <strong className={'-accent'}> carrière e-sport</strong>
                      </p>

                      <Hidden lgUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandleStructures}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </div>
            </div>
            <Hidden mdDown>
              <div className={'-barContainer'}></div>
            </Hidden>
            <div className={'-events'}>
              <div className={'-wrapperContent -linkEvent'}>
                <Link
                  to={routesConfig.events.to()}
                  className={classNames('-title', '-linkWithoutDecoration')}
                >
                  <h2>{t('home.events.title')}</h2>
                  <span>{t('home.events.subtitle')}</span>
                </Link>
                <Slider
                  speed={500}
                  slidesToScroll={2}
                  rows={1}
                  slidesPerRow={1}
                  slidesToShow={4}
                  swipeToSlide
                  arrows
                  infinite
                  centerMode
                  responsive={responsiveConf}
                  autoplay
                >
                  {Object.values(GAME_ACTIVE_LIST).map((key: GAME_ACTIVE_LIST) => (
                    <Link
                      to={routesConfig.eventListWithGameFilter.to(key)}
                      className={classNames(
                        'gameSelectionCaroussel__item',
                        '-linkWithoutDecoration',
                      )}
                      key={key}
                    >
                      <div className={'-paddingWrapperSliderHome'}>
                        <img
                          src={GAME_IMAGES[key as GAME_ACTIVE_LIST] as string}
                          alt={key}
                        />
                      </div>
                    </Link>
                  ))}
                </Slider>
              </div>
              <div className={'-row -timeline'}>
                <Timeline
                  className={'timelineHome'}
                  position={smallerThanMid ? 'right' : 'alternate'}
                >
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden lgDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandlingEvents}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <ManageAccounts className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <h2>Gére tes propres événements</h2>
                      <p>
                        Organise et gére tes propres événnements simplement avec Unpy et
                        sa gestion des <strong className={'-accent'}>inscriptions</strong>
                        , des <strong className={'-accent'}> matchs </strong> et des{' '}
                        <strong className={'-accent'}> scores </strong>. Paramètres et
                        personnalise ton événement avant de le publié et de permettre à
                        les joueurs inscrits sur Unpy de{' '}
                        <strong className={'-accent'}>
                          s'imposer dans ton tournois.
                        </strong>
                      </p>
                      <Hidden lgUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captHandlingEvents}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden lgDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captCashprize}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <EmojiEvents className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector></TimelineConnector>
                    </TimelineSeparator>
                    <TimelineContent>
                      <h2> Participe à des tournois avec cashprize </h2>
                      <p>
                        Unpy peux gérer le{' '}
                        <strong className={'-accent'}>transfert du cashprize</strong> en
                        fin de tournois. Tu n'as qu'a participer à un tournois avec
                        cashprize et ta Balance relative aux récompenses va augmenter.
                        Complète le{' '}
                        <strong className={'-accent'}>formulaire de conformité</strong>{' '}
                        pour transférer l'argent sur ton compte.
                      </p>
                      <Hidden lgUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captCashprize}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden lgDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captGetFunds}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <PriceCheck className={'-icon'} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <h2>
                        Encaisse les inscriptions après avoir remplis toute les conditions
                      </h2>
                      <p>
                        Rembourse tes{' '}
                        <strong className={'-accent'}>frais d'organisation</strong> en
                        appliquant des{' '}
                        <strong className={'-accent'}>frais d'inscriptions.</strong>
                        Le paiement de ces frais sera{' '}
                        <strong className={'-accent'}>obligatoire</strong> pour que les
                        participants puisse{' '}
                        <strong className={'-accent'}> s'inscrire </strong>. Et plusieurs
                        conditions seront nécéssaire à leur transfert sur un compte
                        bancaire dont la <strong className={'-accent'}>fin</strong> du
                        tournois supérieur a{' '}
                        <strong className={'-accent'}>7 jours</strong> (sans réclamation
                        de joueurs),{' '}
                        <strong className={'-accent'}>la disponibilité des fonds</strong>,
                        ainsi que la{' '}
                        <strong className={'-accent'}>fin déclaré de l'événement.</strong>
                      </p>
                      <Hidden lgUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captGetFunds}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem className={'timelineHome-item'}>
                    <Hidden lgDown>
                      <TimelineOppositeContent>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captEditMatchs}
                            className={'-img'}
                          />
                        </div>
                      </TimelineOppositeContent>
                    </Hidden>

                    <TimelineSeparator className="-separator">
                      <TimelineDot className={'-dot'}>
                        <Edit className={'-icon'} />
                      </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                      <h2>
                        Unpy génére les matchs, tu n'a plus qu'a rentrer les scores !
                      </h2>
                      <p>
                        Lors du démarrage de l'événement, une fois les inscriptions
                        terminés Unpy va{' '}
                        <strong className={'-accent'}>généré les matchs</strong> en
                        fonction des paramètres de l'événement et{' '}
                        <strong className={'-accent'}>
                          placer les participant aléatoirement
                        </strong>
                        . Tu n'auras plus qu'a{' '}
                        <strong className={'-accent'}>saisir les scores</strong>, les
                        joueurs concernés seront prévenus et le{' '}
                        <strong className={'-accent'}>
                          classement se mettra a jours automatiquement
                        </strong>
                        .
                      </p>
                      <Hidden lgUp>
                        <div className="-timelineContent">
                          <img
                            alt={'personnalize-profil'}
                            src={captEditMatchs}
                            className={'-img'}
                          />
                        </div>
                      </Hidden>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
