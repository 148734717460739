import { ThemeProvider } from '@mui/material';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import {
  EventParticipant,
  EventParticipantFilters,
} from 'domain/event/participants/EventParticipant';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { EVENT_CONTEXT } from 'primary/context/event/EventContext';
import { EventParticipantDatatable } from 'primary/events/datatable/EventParticipantDatatableParameters';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useResetFiltersOnUnmount } from 'primary/hooks/useResetFiltersOnUnmount';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useContext } from 'react';

import { themeParameters } from '../parameters.helper';

export const Participants = () => {
  const { event } = useContext(EVENT_CONTEXT);
  const { eventParticipantRepository, authRepository } = useContextDependency();
  const t = useTranslate();
  const staticFilters = {
    idEvent: event?.id,
  };
  const useFetchReturn = useFetchWithFilterPage<
    EventParticipant,
    EventParticipantFilters
  >({
    keyStoreFilters: 'event-participants-param-list',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page) => eventParticipantRepository.search(filters, page, 10),
  });
  if (!event) return null;

  useResetFiltersOnUnmount(useFetchReturn.reset);

  return (
    <div className={'eventParticipants'}>
      <PageLayout>
        <ThemeProvider theme={themeParameters}>
          <Title
            title={t('event.parameters.participants.title')}
            level={1}
            compensatePadding
            threeQuarter
            bordered
          />
          <EventParticipantDatatable
            useFetchReturn={useFetchReturn}
            reFetch={useFetchReturn.retry}
            eventId={event.id}
            readOnly={[EventStatusEnum.FINISH, EventStatusEnum.CANCELED].includes(
              event.status,
            )}
          />
        </ThemeProvider>
      </PageLayout>
    </div>
  );
};
