import './ActionButtonsForContent.scss';

import { Cancel, Check, ExitToApp } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type ActionButtonsForContentProps = {
  onClickCancel: () => void;
  onClickValid: () => void;
  onClickExitTo?: () => void;
  loading?: boolean;
};
export const ActionButtonsForContent = ({
  onClickCancel,
  onClickValid,
  onClickExitTo,
  loading,
}: ActionButtonsForContentProps) => {
  const t = useTranslate();

  return (
    <div className={'actionButtonsNotification'}>
      <IconButton
        disabled={loading}
        onClick={onClickCancel}
        className={'cancel'}
        type={'submit'}
        title={t('notification.content.actionBtn.refuseBtn')}
      >
        <Cancel />
      </IconButton>
      <IconButton
        disabled={loading}
        onClick={onClickValid}
        title={t('notification.content.actionBtn.acceptBtn')}
      >
        <Check />
      </IconButton>
      {!!onClickExitTo && (
        <IconButton
          onClick={onClickExitTo}
          title={t('notification.content.actionBtn.seeMoreBtn')}
        >
          <ExitToApp />
        </IconButton>
      )}
      <div style={{ width: '15px' }}>
        {loading && <CircularProgress size={15} color={'info'} />}
      </div>
    </div>
  );
};
