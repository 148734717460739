//import { IFirebaseRepository } from 'domain/firebase/IFirebase.repository';
import { IAnnonceRepository } from 'domain/annonce/Annonce.repository';
import { IMatchRepository } from 'domain/event/match/Match.repository';
import { IEventParticipantRepository } from 'domain/event/participants/EventParticipant.repository';
import { IInvitationLinkRepository } from 'domain/invitationLink/InvitationLink.repository';
import { IInvoiceRepository } from 'domain/payment/IInvoice.repository';
import { IProductRepository } from 'domain/payment/IProduct.repository';
import { ISubscriptionRepository } from 'domain/payment/ISubscription.repository';
import { IProfilPrivacyRepository } from 'domain/privacy/IProfilPrivacyRepository.repository';
import { ISellerAccountRepository } from 'domain/sellerAccount/ISellerAccount.repository';
import { createContext } from 'react';
import { EventBalanceRepository } from 'secondary/balance/EventBalance.repository';
import { PayoutRepository } from 'secondary/payout/Payout.repository';

import { getDefaultContextDependencyForApp } from '../dependency';
import { Auth } from '../domain/auth/Auth.repository';
import { IBackgroundImageRepository } from '../domain/backgroundImage/BackgroundImage.repository';
import { IClassementRepository } from '../domain/classement/Classement.repository';
import { ICountryRepository } from '../domain/country/Country.repository';
import { IDocumentRepository } from '../domain/documents/Document.repository';
import { IEventRepository } from '../domain/event/Event.repository';
import { IGameProfilRepository } from '../domain/gameProfil/GameProfil.repository';
import { ILangRepository } from '../domain/lang/Lang.repository';
import { IMarketPlaceAnnouncementRepository } from '../domain/marketPlaceAnnouncement/MarketPlaceAnnouncement.repository';
import { IMercatoAnnouncementRepository } from '../domain/mercatoAnnouncement/MercatoAnnouncement.repository';
import { IMessageRepository } from '../domain/message/Message.repository';
import { INeonRepository } from '../domain/neon/Neon.repository';
import { INotificationRepository } from '../domain/notification/Notification.repository';
import { IPlatformRepository } from '../domain/platform/Platform.repository';
import { IAllProfilRepository } from '../domain/profil/IAllProfilRepository.repository';
import { IRelationRepository } from '../domain/relation/relation.repository';
import { IRoleStructureRepository } from '../domain/roleStructure/RoleStructure.repository';
import { ISocketRepository } from '../domain/socket/ISocket.repository';
import { IStructureRepository } from '../domain/structure/IStructure.repository';
import { IStructureProfilRepository } from '../domain/structure/IStructureProfil.repository';
import { IStructureMemberRepository } from '../domain/structureMember/StructureMember.repository';
import { ITeamRepository } from '../domain/team/Team.repository';
import { TranslationRepository } from '../domain/translation/Translation.repository';
import { IUserProfilRepository } from '../domain/user/UserProfil.repository';

export type ProfilRepository =
  | ContextDependency['userProfilRepository']
  | ContextDependency['structureProfilRepository'];

export type ContextDependency = {
  authRepository: Auth;
  translationRepository: TranslationRepository;
  eventRepository: IEventRepository;
  mercatoAnnouncementRepository: IMercatoAnnouncementRepository;
  marketPlaceAnnouncementRepository: IMarketPlaceAnnouncementRepository;
  userProfilRepository: IUserProfilRepository;
  structureProfilRepository: IStructureProfilRepository;
  allProfilRepository: IAllProfilRepository;
  countryRepository: ICountryRepository;
  documentRepostory: IDocumentRepository;
  backgroundImageRepostory: IBackgroundImageRepository;
  neonRepository: INeonRepository;
  platformRepository: IPlatformRepository;
  langRepository: ILangRepository;
  gameProfilRepoitory: IGameProfilRepository;
  structureRepository: IStructureRepository;
  roleStructureRepository: IRoleStructureRepository;
  structureMemberRepository: IStructureMemberRepository;
  messageRepository: IMessageRepository;
  notificationRepository: INotificationRepository;
  teamRepository: ITeamRepository;
  classementRepository: IClassementRepository;
  socketRepository: ISocketRepository;
  relationRepository: IRelationRepository;
  productRepository: IProductRepository;
  subscribtionRepository: ISubscriptionRepository;
  profilPrivacyRepository: IProfilPrivacyRepository;
  invoiceRepository: IInvoiceRepository;
  annonceRepository: IAnnonceRepository;
  invitationLinkRepository: IInvitationLinkRepository;
  matchsRepository: IMatchRepository;
  eventParticipantRepository: IEventParticipantRepository;
  sellerAccountRepository: ISellerAccountRepository;
  eventBalanceRepository: EventBalanceRepository;
  payoutRepository: PayoutRepository;
  //messagingRepository: IFirebaseRepository;
};
export const contextDependency = createContext(getDefaultContextDependencyForApp());
