import './EventViewPage.scss';
import '../modals/ModalRegisterEvent.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Box, Chip } from '@mui/material';
import classNames from 'classnames';
import { EventSubStatusEnum } from 'domain/event/EventStatusEnum';
import { TypeTeam } from 'domain/event/TypeTeam';
import { TypeTeamCodeEnum } from 'domain/event/TypeTeamCodeEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import {
  getNumberOfTeamRegistered,
  getSubStatusOfEvent,
} from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { GAME_ACTIVE_LIST, GAME_IMAGES } from 'domain/game/GameActiveList';
import { setTeamModalOpen } from 'domain/modal/store/ModalSlice';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useCountdown } from 'primary/countDown/CountDown';
import { Infos } from 'primary/events/EventViewPageContents/Infos';
import { PreviewEventActions } from 'primary/events/item/PreviewEventActions';
import { MenuEventViewPage } from 'primary/events/MenuEventViewPage';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import ModalCheckout from 'primary/Stripe/ModalCheckout';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { EventImage } from '../../Components/Event/EventImage';
import { KeyValue } from '../../Components/KeyValue/KeyValue';
import { SkeletonNoContent } from '../../Components/SkeletonNoContent/SkeletonNoContent';
import { ModalAddContent } from '../../Components/User/ModalAddContent';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../hooks/useTranslate';
import { menusEventViewPage } from '../MenusEventViewPage.config';
import ModalRegisterEvent from '../modals/ModalRegisterEvent';
import { EventActions } from './EventActions';

interface EventTextInfosProps {
  event?: UnpyEvent | UnpyEventView;
}

const EventTextInfos = ({ event }: EventTextInfosProps) => {
  const t = useTranslate();
  const [daysStartDate, hoursStartDate, minutesStartDate, secondsStartDate, isInPast] =
    useCountdown(event?.startDateTime as Date, true);
  const [days, hours, minutes, seconds] = useCountdown(event?.endSubscribeDate as Date);
  if (!event) return null;
  return (
    <div className={'-textInfos'}>
      <div className={'-column'}>
        <div className={'-row'}>
          <KeyValue
            className={'-keyValueUp'}
            accent
            keyValue={t('event.eventViewPage.header.textInfos.cashprize')}
            value={
              event.cashprize
                ? `${event.cashprize} €`
                : t(`event.eventViewPage.header.textInfos.noCashprize`)
            }
          />
          <KeyValue
            className={'-keyValueUp'}
            accent
            keyValue={t('event.eventViewPage.header.textInfos.registerPrice')}
            value={
              event.registerPrice
                ? `${event.registerPrice} €`
                : t(`event.eventViewPage.header.textInfos.noRegisterPrice`)
            }
          />
        </div>
        {!getSubStatusOfEvent(event) && (
          <KeyValue
            alignStart
            keyValue={t('event.eventViewPage.header.textInfos.status')}
            value={t(`enum.eventStatus.${event.status}`)}
          />
        )}
        {getSubStatusOfEvent(event) &&
          getSubStatusOfEvent(event) === EventSubStatusEnum.SUBSCRIBE_OPEN && (
            <>
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.header.textInfos.status')}
                // @ts-ignore
                value={t(`enum.eventSubStatus.${getSubStatusOfEvent(event)}.title`)}
              />
              <div className={'-row'}>
                <KeyValue
                  alignStart
                  keyValue={t('event.eventViewPage.header.textInfos.remainingPlace')}
                  value={(
                    (event.maxTeam ?? event.maxTeam ?? 0) -
                    (getNumberOfTeamRegistered(event) ?? 0)
                  ).toString()}
                />
                <KeyValue
                  alignStart
                  keyValue={t(
                    'event.eventViewPage.header.textInfos.endSubscribeDateShort',
                  )}
                  value={t(`event.countDownLine`, {
                    day: days,
                    hour: hours,
                    minute: minutes,
                    second: seconds,
                  })}
                />
              </div>
            </>
          )}
        {getSubStatusOfEvent(event) &&
          getSubStatusOfEvent(event) === EventSubStatusEnum.SUBSCRIBE_CLOSE && (
            <>
              <KeyValue
                alignStart
                keyValue={t('event.eventViewPage.header.textInfos.status')}
                // @ts-ignore
                value={t(`enum.eventSubStatus.${getSubStatusOfEvent(event)}.title`)}
              />
              <KeyValue
                alignStart
                keyValue={
                  isInPast
                    ? t('event.eventViewPage.header.textInfos.startDatePassed')
                    : t('event.eventViewPage.header.textInfos.startDateText')
                }
                value={t(`event.countDownLine`, {
                  day: daysStartDate,
                  hour: hoursStartDate,
                  minute: minutesStartDate,
                  second: secondsStartDate,
                })}
              />
            </>
          )}
      </div>
    </div>
  );
};

export type EventViewPageContext = { event: UnpyEvent };

export type EventViewPageProps = {
  eventForPreview?: UnpyEventView;
  isHandled?: boolean;
};

export const EventViewPage = ({ eventForPreview, isHandled }: EventViewPageProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const {
    eventRepository,
    eventParticipantRepository,
    authRepository,
    subscribtionRepository,
  } = useContextDependency();
  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);
  const [isModalCreateStructureOpen, setIsModalCreateStructureOpen] = useState(false);
  const [searchParams, setUrlSearchParams] = useSearchParams();

  const [clientSecretKey, setClientSecretKey] = useState<string | undefined>();
  const [event, , , retry] = useRetrieveFromDomain(
    () => eventRepository.getEventById(id as string),
    undefined,
    !eventForPreview,
  );
  const [teamRegistered, , , retryPlayer] = useRetrieveFromDomain(
    () =>
      eventParticipantRepository.getParticipantOfEventByProfil(
        (searchParams?.get('participantId') ??
          authRepository?.currentUser?.userProfil?.id) as number,
        event?.id as number,
      ),
    undefined,
    !!event &&
      (!!authRepository?.currentUser?.userProfil?.id ||
        !!searchParams?.get('participantId')),
  );

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const eventToUse = eventForPreview ?? event;
  const dispatch = useDispatch();
  const openModalTeam = () => {
    console.log('TEAM', teamRegistered);
    dispatch(
      setTeamModalOpen({
        props: {
          event: event as UnpyEventView,
          teamRegistered: teamRegistered,
          afterChanges: retry as () => void,
        },
      }),
    );
  };

  const register = searchParams.get('register');
  const openTeam = searchParams.get('openTeam');

  const isRegisterOpenedFromRedirect = useRef(false);
  const isTeamOpenedFromRedirect = useRef(false);
  useEffect(() => {
    if (
      !!authRepository.currentUser &&
      !isRegisterOpenedFromRedirect.current &&
      register === 'true'
    ) {
      setIsModalRegisterOpen(true);
      isRegisterOpenedFromRedirect.current = true;
    }
    if (
      !!teamRegistered &&
      !!authRepository.currentUser &&
      !isTeamOpenedFromRedirect.current &&
      openTeam === 'true'
    ) {
      openModalTeam();
      isTeamOpenedFromRedirect.current = true;
    }
  }, [
    register,
    isTeamOpenedFromRedirect.current,
    authRepository.currentUser,
    teamRegistered,
  ]);
  if (!event && !eventForPreview) return <LoadingScreen loading />;

  if (!eventToUse) return null;

  return (
    <section className={classNames('eventViewPage')}>
      <div ref={ref} className={'eventViewPage__header'}>
        <div className={'-overlay'}></div>
        <div className={'-imgContainer'}>
          <SkeletonNoContent displayContent={!!eventToUse}>
            {eventToUse && <EventImage image={eventToUse.publicImageUrl} />}
          </SkeletonNoContent>
        </div>
        <SkeletonNoContent displayContent={!!eventToUse}>
          <div className={'-infoContainer'}>
            <div className={'-headerTitleWithAction'}>
              <h1>{eventToUse?.name}</h1>
              {!eventForPreview && event && (
                <EventActions
                  className={'-actions'}
                  event={event}
                  teamRegistered={teamRegistered}
                  currentUser={authRepository.currentUser}
                  openModalRegister={() => setIsModalRegisterOpen(true)}
                  retry={() => {
                    retry();
                    retryPlayer();
                  }}
                />
              )}
              {eventForPreview && eventToUse && (
                <PreviewEventActions event={eventToUse} isHandled={isHandled} />
              )}
            </div>
            <div className={'-infosWithImage'}>
              {eventToUse?.game.code && (
                <img
                  className={'-imgGame'}
                  src={GAME_IMAGES[eventToUse.game.code as GAME_ACTIVE_LIST]}
                  alt={eventToUse.game.code}
                />
              )}
              {eventToUse && <EventTextInfos event={eventToUse} />}
            </div>
            <div className={'-chipContainer'}>
              <Chip
                className={'-tag'}
                label={t(`enum.typeEventCode.${eventToUse?.typeEvent?.code}`)}
                variant="outlined"
              />
              {event?.typeTeam && (
                <Chip
                  className={'-tag'}
                  label={t(
                    `enum.typeTeamCode.${eventToUse?.typeTeam?.code as TypeTeamCodeEnum}`,
                  )}
                  variant="outlined"
                />
              )}
              <Chip
                className={'-tag'}
                label={
                  eventToUse?.cashprize ||
                  t('event.eventViewPage.header.textInfos.noCashprize')
                }
                variant="outlined"
              />
            </div>
          </div>
        </SkeletonNoContent>
      </div>
      {eventForPreview && (
        <div className={classNames('eventViewPage__contentContainer -preview')}>
          <Infos event={eventToUse} readOnly />
        </div>
      )}
      {!eventForPreview && (
        <div className={classNames('eventViewPage__contentContainer')}>
          <div className={classNames('-menu', { '-fixed': !inView })}>
            <MenuEventViewPage confs={menusEventViewPage.main} />
          </div>
          <div className={classNames('-content', { '-menufixed': !inView })}>
            <Outlet context={{ event: event }} />
          </div>
        </div>
      )}

      <ModalContainer
        handleClose={() => setIsModalRegisterOpen(false)}
        isOpen={isModalRegisterOpen}
        content={
          <Box className={'modal-register'}>
            <ModalRegisterEvent
              idEvent={eventToUse.id}
              handleClose={() => setIsModalRegisterOpen(false)}
              typeTeam={eventToUse.typeTeam as TypeTeam}
              onSubmitted={() => {
                retry();
                retryPlayer();
                setIsModalRegisterOpen(false);
                openModalTeam();
              }}
            />
          </Box>
        }
      />
      {event?.registerPrice && clientSecretKey && (
        <ModalContainer
          handleClose={() => setClientSecretKey(undefined)}
          isOpen={!!clientSecretKey}
          content={
            <ModalCheckout
              title={'Register event'}
              description={'register event desc'}
              clientSecretKey={clientSecretKey}
              handleClose={() => setClientSecretKey(undefined)}
              isOpen={!!clientSecretKey}
            />
          }
        />
      )}
      <ModalAddContent
        isOpen={isModalCreateStructureOpen}
        handleClose={() => setIsModalCreateStructureOpen(false)}
        modalCreateType={'EVENT'}
      />
    </section>
  );
};
