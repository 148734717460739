import { AxiosInstance } from 'axios';
import { CountConsume, toCountConsume } from 'domain/event/CountConsume';
import {
  EventParticipant,
  EventParticipantFilters,
} from 'domain/event/participants/EventParticipant';
import { IEventParticipantRepository } from 'domain/event/participants/EventParticipant.repository';
import { Pageable } from 'domain/pageable/Pageable';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toEventParticipant } from 'secondary/event/participants/RestEventParticipant';
import { toPageable } from 'secondary/RestPageable';

export class EventParticipantRepository
  extends AxiosProviderRepository
  implements IEventParticipantRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getAllPendingInvitationsForUser(): Promise<EventParticipant[]> {
    return this.axios
      .get(`/unpy/api/participant/invitation/pending/all`)
      .then((response) => response.data.map(toEventParticipant));
  }

  search(
    filters: EventParticipantFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<EventParticipant>> {
    return this.axios
      .get('/unpy/api/participants/event/search', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toEventParticipant));
  }
  getEventParticipantById(eventParticipantId: number): Promise<EventParticipant> {
    return this.axios
      .get(`/unpy/api/participant/${eventParticipantId}`)
      .then((response) => toEventParticipant(response.data));
  }

  responseEventParticipantInvitationEvent(
    response: boolean,
    eventParticipantId: number,
  ): Promise<void> {
    return this.axios.put(
      `/unpy/api/participant/${eventParticipantId}/invitation/${
        response ? 'accept' : 'decline'
      }`,
    );
  }

  responseParticipationEvent(
    response: boolean,
    eventParticipantId: number,
    eventId: number,
  ): Promise<void> {
    return this.axios.put(
      `/unpy/api/participant/${eventParticipantId}/event/${eventId}/register/${
        response ? 'accept' : 'decline'
      }`,
    );
  }

  deleteEventParticipantForEvent(
    eventParticipantId: number,
    eventId: number,
  ): Promise<void> {
    return this.axios.delete(
      `/unpy/api/participant/${eventParticipantId}/event/${eventId}/delete`,
    );
  }

  getEventParticipantsOfProfil(
    filters: EventParticipantFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<EventParticipant>> {
    return this.axios
      .get(`/unpy/api/participant/profil`, {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toEventParticipant));
  }

  getParticipantOfEventByProfil(
    profilId?: number,
    eventId?: number,
  ): Promise<EventParticipant | null> {
    if (!profilId || !eventId) return Promise.resolve(null);
    return this.axios
      .get(`/unpy/api/participant/profil/${profilId}/event/${eventId}`)
      .then((response) => {
        return response.data ? toEventParticipant(response.data) : null;
      });
  }

  countEventsParticipationConsume(): Promise<CountConsume> {
    return this.axios
      .get('unpy/api/participant/participation/consume')
      .then((response) => toCountConsume(response.data));
  }

  getAllPendingEventParticipationForUser(): Promise<EventParticipant[]> {
    return this.axios
      .get(`/unpy/api/participant/pending/all`)
      .then((response) => response.data.map(toEventParticipant));
  }
}
