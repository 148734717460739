import {
  Chip,
  CircularProgress,
  FilledInput,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { isArray } from 'lodash';
import React, { forwardRef, Ref } from 'react';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { InputProps, Option, ValueType } from './InputWrapper';

type InputSelectMuiProps<T extends ValueType = string, M = object> = {
  required?: boolean;
  options?: Option<string, M>[];
  isSearchable?: boolean;
  onInputChange?: (newValue: string) => void;
  filterOption?: (opt: FilterOptionOption<Option<string, M>>, input: string) => boolean;
  menuPortalTarget?: HTMLElement | null;
  multiple?: boolean;
  isOptionDisabled?: (option: Option<string, M>) => boolean;
  isLoading?: boolean;
  onLoadingMessage?: string;
  noContentMessage?: string;
  variant?: 'standard' | 'outlined' | 'filled' | 'tag';
} & InputProps<T>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  onClick: (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  },
};

const TagValue = (props: any) => {
  return <Chip label={props.value} {...props} />;
};

export const InputSelectMui = forwardRef(function InputSelectMuiForwarded(
  {
    value,
    onChange,
    options,
    multiple,
    isLoading,
    onLoadingMessage,
    noContentMessage,
    variant,
    disabled,
    placeholder,
  }: InputSelectMuiProps,
  ref: Ref<HTMLElement>,
) {
  const valueDefault = isLoading || options?.length === 0 ? 'search-disabled' : '';
  const Input =
    variant === 'filled'
      ? FilledInput
      : variant === 'outlined'
      ? OutlinedInput
      : //@ts-ignore
      variant === 'tag'
      ? TagValue
      : FilledInput;
  return (
    <FormControl sx={{ width: '100%' }}>
      <Select
        placeholder={placeholder}
        variant={variant}
        labelId="demo-multiple-chip-label"
        renderValue={(selected) => {
          console.log('SELECTED', selected);
          if (multiple) {
            //@ts-ignore
            if (selected?.length > 0) {
              //@ts-ignore
              return selected?.join(', ');
            }
          } else {
            if (selected) {
              return selected;
            } else {
              return <em>{placeholder}</em>;
            }
          }
        }}
        id="demo-multiple-chip"
        displayEmpty
        multiple={multiple}
        // @ts-ignore
        value={valueDefault || (multiple && !isArray(value) ? [value] : value)}
        onChange={onChange}
        input={
          <Input
            id="select-multiple-chip"
            value={valueDefault || (multiple && !isArray(value) ? [value] : value)}
            defaultValue={
              isLoading ? onLoadingMessage : options?.length === 0 ? noContentMessage : ''
            }
          />
        }
        // @ts-ignore
        MenuProps={MenuProps}
        disabled={isLoading || options?.length === 0 || disabled}
      >
        {options?.map((opt) => (
          <MenuItem key={opt.value} value={opt.value} disabled={opt.disabled}>
            {opt.label}
          </MenuItem>
        ))}
        {isLoading && (
          <MenuItem
            key={'search-disabled'}
            value={'search-disabled'}
            disabled={true}
            selected
          >
            <CircularProgress size={15} color={'info'} />
            {onLoadingMessage && <span>{onLoadingMessage}</span>}
          </MenuItem>
        )}
        {!isLoading && options?.length === 0 && noContentMessage && (
          <MenuItem key={'search-disabled'} value={'search-disabled'} disabled={true}>
            <span>{noContentMessage}</span>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
});
