import './CreateTeamPlayerFields.scss';

import classNames from 'classnames';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { AutocompleteUserProfilWrapperFetch } from 'primary/Components/AutoCompleteUserProfil/AutocompleteUserProfil';
import {
  EventRegisterFormData,
  FormStepPlayersData,
} from 'primary/Components/Event/RegisterEventFormStep/FormStepPlayers';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeParam,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useController, UseFormReturn } from 'react-hook-form';

type CreateTeamPlayerFieldsProps = {
  form: UseFormReturn<FormStepPlayersData & EventRegisterFormData>;
  index: number;
  structureId?: string;
  disabledPlayer?: boolean;
  eventId: number;
  creatorIdPlayer: string;
  disabledRole?: boolean;
  onChangeField?: (name: string, property: string, value?: string) => void;
};
export const CreateTeamPlayerFields = ({
  form,
  index,
  structureId,
  disabledPlayer,
  disabledRole,
  onChangeField,
  eventId,
  creatorIdPlayer,
}: CreateTeamPlayerFieldsProps) => {
  const t = useTranslate();
  const { authRepository } = useContextDependency();
  const optRole = Object.values(PlayerRoleEnum).map((roleOpt) => ({
    label: t(`enum.playerRole.${roleOpt as PlayerRoleEnum}`),
    value: roleOpt,
  }));

  const { field: playerNameField } = useController({
    control: form.control,
    name: `players.player-${index}.idPlayer`,
  });
  const { field: playerRoleField } = useController({
    control: form.control,
    name: `players.player-${index}.role`,
  });
  return (
    <div className={classNames('createTeamPlayerFields')}>
      <InputWrapper
        className={'-playersField__player'}
        errors={form.formState.errors}
        isSubmitting={form.formState.isSubmitting}
        Input={AutocompleteUserProfilWrapperFetch}
        inputProps={{
          staticFilters: {
            notInEventId: eventId.toString(),
          },
          idStructure: structureId,
          playersToExclude: [
            ...(Object.values(form.getValues('players'))?.map(
              (p) => p.idPlayer as string,
            ) ?? []),
            authRepository.currentUser?.userProfil.idPlayer as string,
            creatorIdPlayer,
          ],
          placeholder: t('event.form.placeholders.players'),
          ...playerNameField,
          value: form.getValues(`players.player-${index}.idPlayer`),
          disabled: disabledPlayer,
          onChangeSelected: (value) => {
            form.setValue(`players.player-${index}.idPlayer`, value?.toString() ?? ``);
            onChangeField?.(
              `players.player-${index}`,
              'idPlayer',
              value?.toString() ?? '',
            );
          },
        }}
      />
      <InputWrapper
        className={'-playersField__role'}
        errors={form.formState.errors}
        Input={InputSelectMui}
        inputProps={{
          options: optRole,
          placeholder: t('event.form.placeholders.role'),
          menuPortalTarget: document.getElementById('root'),
          ...playerRoleField,
          disabled: disabledRole,
          //@ts-ignore
          onChange: (event: ChangeHandlerTypeParam) => {
            form.setValue(
              `players.player-${index}.role`,
              event.target.value as PlayerRoleEnum,
            );
            onChangeField?.(
              `player-${index}`,
              'role',
              event.target.value?.toString() ?? '',
            );
          },
        }}
      />
    </div>
  );
};
