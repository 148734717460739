import './ModalPublishEvent.scss';

import { Cancel } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { EventPublishForm } from 'primary/events/forms/EventPublishForm';
import React, { FC } from 'react';

import { useTranslate } from '../../hooks/useTranslate';

interface ModalPublishEventProps {
  event: UnpyEvent | UnpyEventView;
  handleClose: () => void;
  onSubmitted: (event: UnpyEvent | UnpyEventView) => void;
}

export const ModalPublishEvent: FC<ModalPublishEventProps> = ({
  handleClose,
  event,
  onSubmitted,
}) => {
  const t = useTranslate();
  return (
    <Box className={'modalPublishEvent'}>
      <div className={'modalPublishEvent__container'}>
        <div className={'modalPublishEvent__container-header'}>
          <div className={'-headerRow'}>
            <h2>{t('event.modalPublish.title', { eventName: event.name })}</h2>
            <IconButton onClick={handleClose} className={'-closeBtn'}>
              <Cancel />
            </IconButton>
          </div>
          <div className={'-headerRow -center'}>
            <KeyValue
              alignStart
              accent
              keyValue={t('event.form.labels.endSubscribeDate')}
              value={formatDate(event.endSubscribeDate, 'readable(with hour)')}
            />
            <KeyValue
              accent
              alignStart
              keyValue={t('event.form.labels.startDateTime')}
              value={formatDate(event.startDateTime, 'readable(with hour)')}
            />
            <KeyValue
              accent
              alignStart
              keyValue={t('event.form.labels.registerPrice')}
              value={event.registerPrice ? `${event.registerPrice} €` : 'N/A'}
            />
          </div>
        </div>
        <div className={'modalPublishEvent__container-content'}>
          <EventPublishForm
            event={event}
            onSubmitted={(event) => {
              onSubmitted(event);
              handleClose();
            }}
          />
        </div>
      </div>
    </Box>
  );
};
