import './EventCard.scss';

import { Add, EmojiEvents, EventBusy, PersonAdd, PlayCircle } from '@mui/icons-material';
import { Skeleton } from '@mui/lab';
import { Button, Chip } from '@mui/material';
import classNames from 'classnames';
import { EventSubStatusEnum } from 'domain/event/EventStatusEnum';
import { getSubStatusOfEvent } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { GAME_ACTIVE_LIST, GAME_IMAGES } from 'domain/game/GameActiveList';
import { formatDate } from 'helper/date.helper';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { routesConfig } from '../../Configs/Routes.config';
import { useTranslate } from '../../hooks/useTranslate';
import { EventImage } from './EventImage';

interface EventCardProps {
  event: UnpyEventView;
  className?: string;
  draftImage?: File;
}

interface EventCardSkeletonProps {
  className?: string;
}

export const EventCardSkelleton = ({ className }: EventCardSkeletonProps) => {
  const t = useTranslate();
  return (
    <div className={classNames('eventCard', className)}>
      <div className={'eventCard__img'}>
        <Skeleton animation="wave" height={'100%'} width="100%" />
      </div>
      <div className={'eventCard__content'}>
        <div className={'eventCard__content-creator'}>
          <Skeleton animation="wave" height={'100%'} width="100%" />
          <span>XXXXX</span>
        </div>
        <div className={'eventCard__content-infos'}>
          <div>
            <span className={'-nameEvent'}>XXXXX</span>
          </div>
          <div>
            <span className={'-startDateEvent'}>XXXXX</span>
          </div>
          <div className={'-overlayAdd'}>
            <Button
              color={'primary'}
              style={{ justifySelf: 'flex-end' }}
              variant={'contained'}
            >
              <Add />
              {t('event.card.skeleton.addOwn')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EventCard = ({ event, className, draftImage }: EventCardProps) => {
  const navigate = useNavigate();
  const t = useTranslate();

  return (
    <div className={classNames('eventCard', className)}>
      <Link to={routesConfig.eventView.to(event.id)} className={'-linkWrapper'}>
        <div className={'-header'}>
          <EventImage
            image={draftImage ? URL.createObjectURL(draftImage) : event.publicImageUrl}
          />
          <div className={'-tagContainer'}>
            <Chip
              size={'small'}
              color={'primary'}
              className={classNames('-tag')}
              label={
                getSubStatusOfEvent(event)
                  ? t(
                      `enum.eventSubStatus.${
                        getSubStatusOfEvent(event) as EventSubStatusEnum
                      }.title`,
                    )
                  : t(`enum.eventStatus.${event.status}`)
              }
            />
            <Chip
              size={'small'}
              color={'primary'}
              className={classNames('-tag')}
              label={
                event?.typeTeam?.code
                  ? t(`enum.typeTeamCode.${event.typeTeam.code}`)
                  : 'Non renseigné'
              }
            />
          </div>
          <div className={'-datesInfos'}>
            <div className={'-colDates'}>
              <div className={'-keyValueIcon -accent -tag'}>
                <PersonAdd className={'-icon'} />
                <span>
                  {event.registerPrice ? `${event.registerPrice} €` : 'Gratuit'}
                </span>
              </div>
              <div className={'-keyValueIcon -accent -tag -marged'}>
                <EmojiEvents className={'-icon'} />
                <span>{event.cashprize ? `${event.cashprize} €` : 'N/A'}</span>
              </div>
            </div>
            <div className={'-colImage'}>
              <div className={'-game'}>
                <img
                  className={'-imgGame'}
                  src={GAME_IMAGES[event.game.code as GAME_ACTIVE_LIST]}
                  alt={event.game.code}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={'eventCard__content'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 8px',
            }}
          >
            <span>{t(`enum.typeEventCode.${event.typeEvent.code}`)}</span>
            {event?.creator && <TagPlayer player={event.creator} embed />}
          </div>
          <div className={'eventCard__content-infos'}>
            <div className={'-infosEventCardContainer'}>
              <div className={'-infosEventCardContainer__name'}>
                <span className={'-nameEvent'}>{event.name}</span>
              </div>
              <div className={'-infosEventCardContainer__infos'}>
                <div className={'-infosCol -left'}>
                  <span>
                    {t('event.eventViewPage.header.textInfos.remainingPlaceFormatted', {
                      count: event.totalParticipants,
                      maxTeam: event.maxTeam,
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className={'-infosEventCardContainer'}>
              <div className={'-keyValueIcon'}>
                <PlayCircle className={'-icon'} />
                <span>{formatDate(event.startDateTime, 'readable(with hour)')}</span>
              </div>
              <div className={'-keyValueIcon'}>
                <EventBusy className={'-icon'} />
                <span>{formatDate(event.endSubscribeDate, 'readable(with hour)')}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
