import 'primary/activities/Modals/PendingTeamsInvitation.scss';

import { Cancel } from '@mui/icons-material';
import { Box, Divider, IconButton } from '@mui/material';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { UserProfilView } from 'domain/user/UserProfilView';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { ActionButtonsForContent } from 'primary/MessageList/contents/ActionButtonsForContent';
import { getOnClickResponseInvitationParticipationEvent } from 'primary/MessageList/contents/NotificationInvitationTeamEventContent';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ModalPendingTeamsInvitationProps = {
  isOpen?: boolean;
  handleClose: () => void;
  invitationList: EventParticipant[];
  afterSubmit: () => void;
  loading?: boolean;
};

export const ModalPendingTeamsInvitation = ({
  isOpen,
  handleClose,
  afterSubmit,
  invitationList,
  loading,
}: ModalPendingTeamsInvitationProps) => {
  const t = useTranslate();
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={!!isOpen}
      content={
        <Box className={'modal-pending-team-invitation'}>
          <div className={'-header'}>
            <h2>{t('modals.pendingTeamsInvitation.title')}</h2>
            <IconButton onClick={() => handleClose()}>
              <Cancel />
            </IconButton>
          </div>
          <ListPendingTeamsInvitation
            invitationList={invitationList}
            afterSubmit={afterSubmit}
            loadingFromButton={loading}
          />
        </Box>
      }
    />
  );
};

type ListPendingTeamsInvitationProps = {
  invitationList: EventParticipant[];
  afterSubmit: () => void;
  loadingFromButton?: boolean;
};

const ListPendingTeamsInvitation = ({
  invitationList,
  afterSubmit,
  loadingFromButton,
}: ListPendingTeamsInvitationProps) => {
  const [loading, setLoading] = useState(false);
  const { teamRepository } = useContextDependency();
  const t = useTranslate();
  const navigate = useNavigate();
  return (
    <div className={'-listPendingTeamsInvitation'}>
      {invitationList?.map((invitation) => {
        return (
          <div key={invitation?.id} className={'-pendingTeamsInvitation'}>
            <TagPlayer
              embed
              player={
                invitation.team.players.find((p) => p.role === PlayerRoleEnum.CHIEF)
                  ?.player as UserProfilView
              }
            />
            <Divider />
            <div className={'-infos'}>
              <div className={'-teamInfos'}>
                <KeyValue
                  className={'-margedRight'}
                  accent
                  keyValue={'Equipe :'}
                  value={invitation.team.name}
                />
                <KeyValue
                  className={'-margedRight'}
                  accent
                  keyValue={'Event :'}
                  value={invitation.event.name}
                />
              </div>
            </div>
            <Divider />
            <div className={'-footerActions'}>
              <ActionButtonsForContent
                loading={loadingFromButton || loading}
                onClickCancel={getOnClickResponseInvitationParticipationEvent(
                  teamRepository,
                  t,
                  false,
                  afterSubmit,
                  invitation.team.id,
                  invitation.event.id,
                  setLoading,
                  false,
                )}
                onClickValid={getOnClickResponseInvitationParticipationEvent(
                  teamRepository,
                  t,
                  true,
                  afterSubmit,
                  invitation.team.id,
                  invitation.event.id,
                  setLoading,
                  true,
                )}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
