import './UnpyUpOffers.scss';

import { Button, Hidden } from '@mui/material';
import classNames from 'classnames';
import { ISubscriptionRepository } from 'domain/payment/ISubscription.repository';
import { Product } from 'domain/payment/Product';
import { UnpySession } from 'domain/payment/UnpySession';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { CardDurationOffer } from 'primary/Up/DurationOffer';
import { ModalWaitingForActions } from 'primary/Up/ModalWaitingForActions';
import React, { useMemo, useState } from 'react';
import { ProductCodeEnum } from 'secondary/payment/ProductCodeEnum';

const DURATION_SUBSCRIBE = {
  1: {
    normal: ProductCodeEnum.ABO_UP_1,
    premium: ProductCodeEnum.ABO_UP_PREMIUM_1,
  },
  6: {
    normal: ProductCodeEnum.ABO_UP_6,
    premium: ProductCodeEnum.ABO_UP_PREMIUM_6,
  },
  12: {
    normal: ProductCodeEnum.ABO_UP_12,
    premium: ProductCodeEnum.ABO_UP_PREMIUM_12,
  },
};

type UnpyUpOffersProps = {
  embedded?: boolean;
  products: Product[];
};

export const useOnClickCheckout =
  (
    setIsWaitingOpen: (value: boolean) => void,
    subscriptionRepository: ISubscriptionRepository,
    onSubmitted: (session: UnpySession) => void,
  ) =>
  (productId: number, type: 'abo' | 'prod') =>
  () => {
    setIsWaitingOpen(true);
    subscriptionRepository.initSession(productId).then(onSubmitted);
  };
export const UnpyUpOffers = ({ embedded, products }: UnpyUpOffersProps) => {
  const { authRepository, productRepository } = useContextDependency();
  const [durationDisplayed, setDurationDisplayed] = React.useState<1 | 6 | 12>(1);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [isModalWaitingOpen, setIsModalWaitingOpen] = useState(false);

  const prodBoost = useMemo(
    () => products.find((prod) => prod.code === ProductCodeEnum.PROD_BOOST),
    [products],
  );
  const prodPass = useMemo(
    () => products.find((prod) => prod.code === ProductCodeEnum.PASS),
    [products],
  );
  const prodSubbscribeUp = useMemo(
    () =>
      products.find((prod) => prod.code === DURATION_SUBSCRIBE[durationDisplayed].normal),
    [products, durationDisplayed],
  );
  const prodSubbscribeUpPremium = useMemo(
    () =>
      products.find(
        (prod) => prod.code === DURATION_SUBSCRIBE[durationDisplayed].premium,
      ),
    [products, durationDisplayed],
  );
  const onSubmitted = (session: UnpySession) => {
    window.location.replace(session.url);
  };
  const t = useTranslate();
  const { subscribtionRepository } = useContextDependency();
  const onClickCheckout = useOnClickCheckout(
    setIsModalWaitingOpen,
    subscribtionRepository,
    onSubmitted,
  );

  return (
    <div
      className={classNames('-containerOffers', {
        '-embedded': embedded,
      })}
    >
      <Hidden smUp>
        <div className={'-durationOffers__headerMenu'}>
          <Button
            variant={'text'}
            onClick={() => setDurationDisplayed(1)}
            color={'primary'}
            className={classNames({ '-active': durationDisplayed === 1 })}
          >
            {t('products.durationLabel.1')}
          </Button>
          <Button
            variant={'text'}
            onClick={() => setDurationDisplayed(6)}
            color={'primary'}
            className={classNames({ '-active': durationDisplayed === 6 })}
          >
            {t('products.durationLabel.6')}
          </Button>
          <Button
            variant={'text'}
            onClick={() => setDurationDisplayed(12)}
            color={'primary'}
            className={classNames({ '-active': durationDisplayed === 12 })}
          >
            {t('products.durationLabel.12')}
          </Button>
        </div>
      </Hidden>
      <div className={classNames('-contentOffers')}>
        <div className={'-durationOffersContainer'}>
          <Hidden smDown>
            <div className={'-durationOffers__headerMenu'}>
              <Button
                variant={'text'}
                onClick={() => setDurationDisplayed(1)}
                color={'primary'}
                className={classNames({ '-active': durationDisplayed === 1 })}
              >
                {t('products.durationLabel.1')}
              </Button>
              <Button
                variant={'text'}
                onClick={() => setDurationDisplayed(6)}
                color={'primary'}
                className={classNames({ '-active': durationDisplayed === 6 })}
              >
                {t('products.durationLabel.6')}
              </Button>
              <Button
                variant={'text'}
                onClick={() => setDurationDisplayed(12)}
                color={'primary'}
                className={classNames({ '-active': durationDisplayed === 12 })}
              >
                {t('products.durationLabel.12')}
              </Button>
            </div>
          </Hidden>

          <div className={'-durationOffers'}>
            <div>
              {prodSubbscribeUp && (
                <CardDurationOffer
                  duration={durationDisplayed}
                  subscribeType={'normal'}
                  product={prodSubbscribeUp}
                  embedded={embedded}
                  key={prodSubbscribeUp.code}
                  onClickCheckout={onClickCheckout(prodSubbscribeUp.id, 'abo')}
                  isAlreadySubscribed={
                    authRepository?.currentUser?.subscription?.product?.code ===
                    prodSubbscribeUp.code.toString()
                  }
                  canChange={
                    authRepository?.currentUser?.subscription?.product?.code &&
                    authRepository?.currentUser?.subscription?.product?.code !==
                      prodSubbscribeUp?.code?.toString()
                  }
                />
              )}
            </div>
            <div>
              {prodSubbscribeUpPremium && (
                <CardDurationOffer
                  duration={durationDisplayed}
                  subscribeType={'premium'}
                  product={prodSubbscribeUpPremium}
                  onClickCheckout={onClickCheckout(prodSubbscribeUpPremium.id, 'abo')}
                  embedded={embedded}
                  key={prodSubbscribeUpPremium.code}
                  isAlreadySubscribed={
                    authRepository?.currentUser?.subscription?.product?.code ===
                    prodSubbscribeUpPremium.code.toString()
                  }
                  canChange={
                    authRepository?.currentUser?.subscription?.product?.code &&
                    authRepository?.currentUser?.subscription?.product?.code !==
                      prodSubbscribeUpPremium?.code?.toString()
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalWaitingForActions
        handleClose={() => setIsModalWaitingOpen(false)}
        isOpen={isModalWaitingOpen}
      />
    </div>
  );
};
